import React, { useState } from 'react';
import './App.css';
import logo from './soldinowlogo256x256.png';
import preview1 from './preview1.png';
import preview2 from './preview2.png';
import preview3 from './preview3.png';
import preview4 from './preview4.png';
import previewVideo from './preview.mp4'; // Import the video file
import ai from './ai.png';
import faceid from './faceid.png';

function App() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slides = [preview1, preview2, preview3, preview4, previewVideo]; // Include the video in the slides array

  const moveCarousel = (direction) => {
    setCurrentSlide((prevSlide) => (prevSlide + direction + slides.length) % slides.length);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const loader = document.getElementById('loader-container');
    const formContainer = document.getElementById('form-and-loader-container');

    loader.style.display = 'block';

    fetch('https://tigger.soldinow.com/MerchantQueue', {
      method: 'POST',
      body: formData,
    })
    .then((response) => response.text())
    .then((data) => {
      loader.style.display = 'none';
      formContainer.innerHTML = `<div class="success-message">Registration Successful</div>`;
    })
    .catch((error) => {
      console.error('Error:', error);
      loader.style.display = 'none';
      formContainer.innerHTML = `<div class="success-message">Registration Successful</div>`;
    });
  };

  return (
    <div className="app">
      <div className="background-container"></div>
      <div className="welcome-section">
        <img src={logo} alt="SoldiNow Logo" className="logo" />
        <h1 className="welcome-title">Welcome to Soldi Now</h1>
        <p className="welcome-subtitle">Revolutionizing Payments with Ultra-Low Cost Transactions</p>
      </div>

      <div className="preview-section">
        <h2 className="sub">App Preview</h2>
        <div className="carousel">
          <div className="carousel-inner" style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
            {slides.map((slide, index) => (
              <div key={index} className="carousel-item">
                {index === 4 ? (
                  <video src={slide} autoPlay muted loop></video>
                ) : (
                  <img src={slide} alt={`App preview ${index}`} />
                )}
              </div>
            ))}
          </div>
          <button className="carousel-control prev" onClick={() => moveCarousel(-1)}>&#10094;</button>
          <button className="carousel-control next" onClick={() => moveCarousel(1)}>&#10095;</button>
        </div>
      </div>

      <div className="features-section">
        <h2 className="sub">Key Features</h2>
        <div className="features">
          <div className="feature">
            <img src={ai} alt="Fraud Detection" />
            <h3>Fraud Detection using AI</h3>
            <p>Utilizes advanced AI algorithms to detect and prevent fraudulent transactions in real-time.</p>
          </div>
          <div className="feature">
            <img src={faceid} alt="Face ID" />
            <h3>Payment Authorization using Face ID</h3>
            <p>Seamless and secure payment authorizations using Apple's state-of-the-art Face ID technology.</p>
          </div>
          <div className="feature">
            <img src={logo} alt="ACH and FedNow" />
            <h3>Ultra Low-Cost Transactions</h3>
            <p>Leverage Direct Bank Transfers for secure, ultra-low-cost transactions, offering a superior alternative to traditional credit card processing.</p>
          </div>
        </div>
      </div>

      <div className="form-section">
        <div id="form-and-loader-container" className="form-and-loader-container">
          <h2 className="sub">Register</h2>
          <form id="signup-form" onSubmit={handleSubmit}>
            <input type="text" name="name" placeholder="Your Name" required />
            <input type="email" name="email" placeholder="Your Email" required />
            <input type="text" name="business_name" placeholder="Business Name" required />
            <button type="submit">Get in Queue for Early Access</button>
          </form>
          <div id="loader-container" className="loader-container hidden">
            <div className="loader"></div>
          </div>
        </div>
      </div>

      <footer className="footer">
        <div className="footer-content">
          <p>Contact Us</p>
          <p>Email: support@soldinow.com</p>
          <p>Soldi Now Inc, Los Altos, CA 94024</p>
        </div>
      </footer>
    </div>
  );
}

export default App;
